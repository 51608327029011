<template>
  <div id="login">
    <img class="bg" src="@/assets/img/login_bg.png" />
    <div class="login-warpper">
      <div class="logo">
        <img src="@/assets/img/logo.png" />
      </div>
      <div class="form-container">
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="72px"
          label-position="left"
        >
          <el-form-item label="用户名" prop="account">
            <el-input
              v-model="form.account"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              type="password"
              show-password
              v-model="form.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code" style="position：relative">
            <el-input v-model="form.code" placeholder="请输入验证码" maxlength="4"></el-input>
            <div class="code" @click="clickCode">
              <verifyCode
                :changeCode.sync="identifyCode"
                style="float: left"
                ref="code"
              ></verifyCode>
            </div>
          </el-form-item>
        </el-form>
        <div class="btn-line">
          <div class="submit-btn" @click="submitForm">
            <span>登录</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import verifyCode from "./components/verify";
import md5 from "js-md5";
import { login } from "@/api/login/login";
export default {
  components: {
    verifyCode,
  },
  data() {
    return {
      identifyCode: "", //当前生成的验证码
      form: {
        account: "",
        password: "",
        code: "",
      },
      rules: {
        account: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onWindowKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onWindowKeyUp);
  },
  methods: {
    // 监听window 键盘按下事件
    onWindowKeyUp(e) {
      if (e.keyCode === 13) {
        this.submitForm();
      }
    },
    //切换验证码
    clickCode() {
      this.$refs.code.changeCode();
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (this.identifyCode.toLowerCase() != this.form.code.toLowerCase()) {
          this.clickCode();
          this.$message.error("验证码错误");
          return;
        }
        if (valid) {
          let mm = md5(md5(md5(this.form.password)));
          let params = {
            account: this.form.account,
            password: mm,
          };
          try {
            const res = await login(params)
            if (res.code == 200) {
              const {token,account,managerSysMenuVOList} = res.result
              sessionStorage.setItem('token',token)
              sessionStorage.setItem('account',account)
              localStorage.setItem('minutes', res.result.minutes * 60000)
              sessionStorage.setItem('menu',JSON.stringify(managerSysMenuVOList))
              sessionStorage.setItem('amenu',this.handleMenu(managerSysMenuVOList))
              this.$message.success("登录成功");
              this.$router.push("/");
            } else {
              this.$message.error(res.message);
            }
            this.clickCode();
          }catch(error) {
            console.log(error)
          }
        } else {
          console.error("error submit!!");
          return;
        }
      });
    },
    handleMenu(managerSysMenuVOList) {
      const list = []
      const menu = [...managerSysMenuVOList]
      for (let i = 0; i < menu.length; i++) {
        console.log(menu[i].pp,898989898)
        menu[i].pp = menu[i].pp || menu[i].id
        const { childMenu, pp } = menu[i]
        list.push(pp)
        if (childMenu && childMenu.length) {
          childMenu.forEach(item => {
            item.pp = `${pp}-${item.id}`
          })
          menu.push(...childMenu)
        }
      }
      sessionStorage.setItem('jurisdictionList', JSON.stringify(list))
    }
  },
};
</script>
<style lang="scss" socped>
#login {
  width: 100%;
  height: 100vh;
  position: relative;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /deep/ .el-col {
    margin-bottom: unset !important;
  }
}
.login-warpper {
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 200px;
  transform: translateX(-50%);
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .logo {
    margin-bottom: 44px;
    img {
      width: 98px;
    }
  }
}

.form-container {
  width: 440px;
  height: 291px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px 30px 30px 20px;
  /deep/ .el-form-item__label {
    font-size: 16px;
    &::before {
      display: none;
    }
  }
  /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
  }
  /deep/ .el-input__inner {
    border-radius: 10px;
    font-size: 16px;
  }
  .code {
    width: 108px;
    height: 40px;
    position:absolute;
    top:0;
    right:0;
    // background-color: #ff4000;
    margin-left: 10px;
    border-radius: 10px;
    flex-shrink: 0;
    overflow: hidden;
    cursor: pointer;
  }
}
.btn-line {
  padding-top: 8px;
}
.submit-btn {
  width: 100%;
  height: 40px;
  background-color: #f53131;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    font-size: 16px;
    color: #ffffff;
  }
}
</style>
