<template>
	<div id="topicDetail">
		<div class="nav-bar">
			<div class="back-btn" v-if="historyLength" @click="goBack">
				<i class="el-icon-back"></i>
			</div>
			<div class="title">
				<span>专题详情</span>
			</div>
		</div>
		<div class="page-content">
			<div class="page-wrapper">
				<div class="title">
					<span>{{ detail.title || '' }}</span>
				</div>
				<div class="content" v-html="detail.content || ''"></div>
			</div>
		</div>
	</div>
</template>
<script>
import { detail } from '@/api/advertisement/topic'

export default {
	data() {
		return {
			detail: {},
			historyLength: 0,
		}
	},
	created() {
		const { query } = this.$route
		const { id } = query
		this.getDetail(id)
		this.historyLength = window.history.length
	},
	methods: {
		goBack() {
			this.$router.go(-1)
		},
		// 获取专题详情
		getDetail(id) {
			if (!id) return
			detail({
				id,
			}).then((res) => {
				this.detail = res.result || {}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
#topicDetail {
	width: 100%;
	height: 100vh;
	background-color: #f5f5f9;
	display: flex;
	flex-flow: column wrap;
}
.nav-bar {
	width: 100%;
	height: 3rem;
	background-color: #fff;
	flex-shrink: 0;
	position: relative;
	.title {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.back-btn {
		position: absolute;
		left: 1rem;
		top: 50%;
		transform: translateY(-50%);
		padding: 0.5rem;
		cursor: pointer;
		i {
			font-size: 1.2rem;
			color: $--color-primary;
		}
	}
}
.page-content {
	flex: 1;
	padding: 1.5rem 1rem;
	overflow-x: hidden;
	overflow-y: auto;
	.page-wrapper {
		width: 100%;
		height: 100%;
		background-color: #fff;
		border-radius: 0.8rem;
		padding: 0.8rem;
	}
	.title {
		text-align: center;
	}
}
</style>
